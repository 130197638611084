import React from "react";
import features from "../assets/ReportsFeat.svg";
import list from "../assets/list2.svg";
const Reports = () => {
  return (
    <div className="flex text-left">
      <img src={features} className="w-1/2 lg:block hidden"></img>
      <div className="w-full lg:ml-10">
        <div className="font-bold " style={{ fontSize: "32px" }}>
          Reports
        </div>
        <div className="text-tools2 my-3">
          Discover valuable business insights using our powerful Reports
          feature! Explore a wide array of reports, including Balance Sheets,
          Stock Reports, and more. Enhance your decision-making, get a clear
          view of your business, and harness the full potential of our ERP tool
          for customized and detailed reporting based on your requirements.{" "}
        </div>
        <ul className="text-tools2">
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Stock Report
          </li>
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Balance Sheet
          </li>
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Stock Value Report
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Reports;
