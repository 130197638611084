import React from "react";
import features from "../assets/PurchaseFeat.svg";
import list from "../assets/list2.svg";
const Purchase = () => {
  return (
    <div className="flex text-left">
      <img src={features} className="w-1/2 lg:block hidden"></img>
      <div className="w-full lg:ml-10">
        <div className="font-bold " style={{ fontSize: "32px" }}>
          Purchase
        </div>
        <div className="text-tools2 my-3">
          Our all-in-one Purchase feature simplifies everything from ordering to
          returns. Experience efficiency and convenience like never before as
          our app allows you to create and manage purchase orders in seconds
          thus eliminating your paperwork and missing deadlines. Explore
          Purchase Orders, Inquiries, Returns and sales effortlessly and at your
          convenience!
        </div>
        <ul className="text-tools2">
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Purchase Order
          </li>
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Purchase Request
          </li>
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Purchase Invoice
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Purchase;
