import React from "react";
import location from "../assets/locate.svg";
import call from "../assets/call.svg";
import mail from "../assets/mail.svg";
import place from "../assets/place.svg";
import logo from "../assets/logo-display.svg";
import { FaLocationDot } from "react-icons/fa6";
import GoogleMap from "../components/GoogleMap";
import { ToastContainer, toast,Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contact = () => {
  const [isSmScreen, setIsSmScreen] = React.useState(false);
  const handleResize = () => {
    setIsSmScreen(window.innerWidth < 768);
  };
  const toastNotification=()=>{
    toast.success('Inquiry has been sent successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
  }
  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="mt-10">
      <ToastContainer/>
      <div className="flex items-center justify-center mb-1">
        <FaLocationDot fontSize="2.2rem" color="#01AAE8" />
        <p className="font-bold text-navbarBlue text-[22px] lg:text-[44px]">
          Locate Us Now
        </p>
      </div>
      <div className="relative text-center mb-10">
        {/* <img src={location} className="w-full" /> */}
        <GoogleMap />
      </div>
      <div className="bg-whtie relative mb-10 md:mb-0">
        <div
          className="bg-numbers w-full absolute top-0 z-0 "
          style={{ height: isSmScreen ? "100%" : "600px" }}
        ></div>
        <div className="block md:flex px-5 lg:px-10 py-10 relative">
          <div
            className="bg-white md:w-1/2 w-full mr-5 px-2 lg:px-8 pt-8 "
            style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)" }}
          >
            <div className="flex">
              <img src={logo}></img>
              <div className="font-bold my-auto tex-[15px] lg:text-[35px]">
                Contact Us
              </div>
            </div>
            <input
              placeholder="Name"
              className="bg-inputs w-full py-2 px-3 rounded-md my-4 text-13px lg:text-[20px]"
            ></input>
            <br></br>
            <input
              placeholder="Email"
              className="bg-inputs w-full py-2 px-3 rounded-md my-4 text-13px lg:text-[20px]"
            ></input>
            <br></br>
            <input
              placeholder="Enter Subject"
              className="bg-inputs w-full py-2 px-3 rounded-md my-4 text-13px lg:text-[20px]"
            ></input>
            <br></br>
            <textarea
              placeholder="Write Your Concern"
              className="bg-inputs w-full py-2 px-3 rounded-md my-4 h-[300px] text-13px lg:text-[20px]"
            ></textarea>
            <br></br>
            <button onClick={toastNotification} className="text-[13px] lg:text-[25px] bg-gradient-to-b from-home to-linear text-white w-full py-2 mb-8 rounded-md border-2 border-transparent glow-on-hover hover:text-navbarBlue hover:from-white hover:to-white hover:border-border">
              Send Message
            </button>
          </div>
          <div className="md:w-1/2 w-full md:ml-5 mt-5 md:mt-0 text-white">
            <div className="font-bold text-[16px] lg:text-[28px]">
              Contact with us
            </div>
            <div className="font-bold text-[22px] lg:text-[44px]">
              let's work together?
            </div>
            <div className="text-base mb-5">
              Get in touch with us to discuss how we can help you achieve your
              goals.
            </div>
            <div className="mt-10 lg:mt-20">
              <div className="flex my-8 items-center">
                <div
                  className="bg-white rounded-md"
                  style={{
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 10px 0px rgba(25, 18, 60, 0.75)",
                  }}
                >
                  <img src={call} className="mx-auto"></img>
                </div>
                <div className="ml-5">
                  <div>HAVE ANY QUESTION?</div>
                  <div>+91 9325901176</div>
                </div>
              </div>
              <div className="flex my-8 items-center">
                <div
                  className="bg-white rounded-md"
                  style={{
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 10px 0px rgba(25, 18, 60, 0.75)",
                  }}
                >
                  <img src={mail} className="mx-auto"></img>
                </div>
                <div className="ml-5">
                  <div>SEND EMAIL</div>
                  <div>santosh@abssoftware.in</div>
                </div>
              </div>
              <div className="flex my-8 items-center">
                <div
                  className="bg-white rounded-md"
                  style={{
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 10px 0px rgba(25, 18, 60, 0.75)",
                  }}
                >
                  <img src={place} className="mx-auto"></img>
                </div>
                <div className="ml-5">
                  <div>VISIT ANYTIME</div>
                  <div>
                    Sterling Center, 2nd Floor office no 231, Pune Camp-411001
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
