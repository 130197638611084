import React from "react";
import Numbers from "./Numbers";

const Numinterest = () => {
  return (
    <div className="bg-numbers flex flex-col md:flex-row items-center justify-between text-white px-[30px] py-[60px] lg:p-[60px]">
      <div className="w-full lg:w-1/2">
        <p className="font-bold md:text-[48px] md:leading-[57px] text-[30px] leading-[41px] text-center lg:text-left">
          Numbers That Might <br /> Interest You
        </p>
        <p className="hidden lg:block my-2 w-10/12 text-left text-[20px] leading-[32px] font-medium">
          Revealing you our key performance Metrics, Actionable Data, and the
          numbers that Drive your Business for{" "}
          <span className="font-bold">Choosing Us</span>.
        </p>
      </div>
      <div className="flex lg:mx-0 justify-between mt-5 lg:mt-0 w-full lg:w-[560px]">
        <div className="mx-2">
          <Numbers num="300" subTitle="K" description="Annual sales" />
          <Numbers num="100" subTitle="%" description="Customer Satisfaction" />
        </div>
        <div className="mx-2">
          <Numbers num="4800" subTitle="+" description="User rating" />
          <Numbers num="2400" subTitle="+" description="Customers" />
        </div>
      </div>
      <div className="lg:hidden text-center text-[13px] leading-[20px] font-medium my-2">
        Revealing you our key performance Metrics, Actionable Data, and the
        numbers that Drive your Business for{" "}
        <span className="font-bold">Choosing Us</span>.
      </div>
    </div>
  );
};

export default Numinterest;
