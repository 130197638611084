import React from "react";
import list from "../assets/list.svg";
const Benefits = (props) => {
  return (
    <div className=" bg-home text-white sm:mx-2 my-5 rounded-md px-5 py-5 hover:scale-105 glow-on-hover">
      <div className="flex">
        <img src={list} className="mr-5"></img>
        <div className="text-2xl">{props.title}</div>
      </div>
      <div className="ml-9" style={{ fontSize: "16px" }}>
        {props.value}
      </div>
    </div>
  );
};
export default Benefits;
