import React from 'react';

const BottomLine = () => {
    return (
        <div className="bg-gradient-to-r from-home to-linear my-3 mx-auto lg:mx-0 w-[65px] h-[6px] rounded-lg relative flex flex-col items-center jsutify-center">
            <div className="absolute top-[1px] h-1 w-1 bg-white rounded-full animate-dot1" />
            <div className="absolute top-[1px] h-1 w-1 bg-white rounded-full animate-dot2" />
        </div>
    )
}

export default BottomLine