import React from "react";
import features from "../assets/SalesFeat.svg";
import list from "../assets/list2.svg";
const Sales = () => {
  return (
    <div className="flex text-left">
      <img src={features} className="w-1/2 hidden lg:block"></img>
      <div className="w-full lg:ml-10">
        <div className="font-bold " style={{ fontSize: "32px" }}>
          Sales
        </div>
        <div className="text-tools2 my-3">
          Say goodbye to messy spreadsheets and endless paperwork as from lead
          management to tracking your progress, we have got it all! Our features
          are designed to enhance your team's capabilities, making your sales
          journey smoother and more effective with feature like Sales Enquiry,
          Sales Quotation, Sales Invoice and many more.
        </div>
        <ul className="text-tools2">
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Sales Enquiry
          </li>
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Sales Quotation
          </li>
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Sales Invoice
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Sales;
