import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/logo.svg";
import { Link, useLocation } from "react-router-dom";
import { IoReorderThree } from "react-icons/io5";
import whitelogo from "../assets/whitelogo.svg";

const Navbar = () => {
  const [activetab, setActivetab] = useState("");
  const [visible, setVisible] = useState(false);
  const ref = useRef();

  const location = useLocation();
  const path = location.pathname.substring(1);

  useEffect(() => {
    if (path == "") {
      setActivetab("Home");
    } else if (path == "features") {
      setActivetab("Features");
    } else if (path == "pricing") {
      setActivetab("Pricing");
    } else if (path == "services") {
      setActivetab("Services");
    } else if (path == "about") {
      setActivetab("About");
    } else if (path == "contact") {
      setActivetab("Contact");
    } else if (path == "pos") {
      setActivetab("Pos");
    }
  }, [path]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      className={`flex justify-between relative z-10 ${
        activetab == "Home" ? "" : " shadow-md"
      } `}
    >
      <Link to="" className="cursor-pointer">
        <img
          src={logo}
          style={{ height: "50px", marginLeft: "30px", marginTop: "30px" }}
          className="hidden lg:block"
          alt="Logo"
        ></img>

        {activetab == "Home" ? (
          <img
            src={whitelogo}
            style={{ height: "50px" }}
            className="lg:hidden ml-5 mt-2"
            alt="White Logo"
          ></img>
        ) : (
          <img
            src={logo}
            style={{ height: "50px" }}
            className="lg:hidden ml-5 mt-2"
            alt="Logo"
          ></img>
        )}
      </Link>

      <div
        className="w-6/12 mr-5 lg:block hidden"
        style={{ marginTop: "45px" }}
      >
        <ul
          className="flex justify-between font-semibold"
          style={{ fontSize: "17px" }}
        >
          <Link to="">
            <li
              className={
                activetab == "Home"
                  ? "text-navbarBlue border-b-2 border-border"
                  : "hover:border-b-2 hover:border-black "
              }
              onClick={() => setActivetab("Home")}
            >
              Home
            </li>
          </Link>
          <Link to="/features">
            <li
              className={
                activetab == "Features"
                  ? "text-navbarBlue border-b-2 border-border"
                  : "hover:border-b-2 hover:border-black"
              }
              onClick={() => setActivetab("Features")}
            >
              Features
            </li>
          </Link>
          <Link to="/pos">
            <li
              className={
                activetab == "Pos"
                  ? "text-navbarBlue border-b-2 border-border"
                  : "hover:border-b-2 hover:border-black"
              }
              onClick={() => setActivetab("Pos")}
            >
              POS
            </li>
          </Link>
          {/* <Link to="/pricing"><li className={activetab == 'Pricing' ? 'text-navbarBlue border-b-2 border-border' : 'hover:border-b-2 hover:border-black'} onClick={() => setActivetab('Pricing')}>Pricing</li></Link> */}
          <Link to="/services">
            <li
              className={
                activetab == "Services"
                  ? "text-navbarBlue border-b-2 border-border"
                  : "hover:border-b-2 hover:border-black"
              }
              onClick={() => setActivetab("Services")}
            >
              Services
            </li>
          </Link>
          <Link to="/about">
            <li
              className={
                activetab == "About"
                  ? "text-navbarBlue border-b-2 border-border"
                  : "hover:border-b-2 hover:border-black"
              }
              onClick={() => setActivetab("About")}
            >
              About Us
            </li>
          </Link>
          <Link to="/contact">
            <li
              className={
                activetab == "Contact"
                  ? "text-navbarBlue border-b-2 border-border"
                  : "hover:border-b-2 hover:border-black"
              }
              onClick={() => setActivetab("Contact")}
            >
              Contact Us
            </li>
          </Link>
        </ul>
      </div>
      <div className="flex" ref={ref}>
        <button
          onClick={() => window.open("https://erp.abssoftware.in", "_blank")}
          className={`${
            activetab == "Home"
              ? "text-navbarBlue bg-white"
              : "bg-home text-white hover:border-boder glow-on-hover"
          } border-2 border-transparent hover:scale-105  rounded-lg py-auto font-semibold mb-5 text-sm lg:h-12 h-10 lg:mt-8 mt-3 lg:mr-14 sm:px-7 px-2 transition-all duration-300 ease`}
        >
          DEMO
        </button>
        <div>
          <IoReorderThree
            color={`${activetab == "Home" ? "white" : "black"}`}
            onClick={() => setVisible(!visible)}
            className="lg:hidden block mr-3 ml-2 mt-2 sm:mt-1 sm:text-6xl text-5xl"
          />
          {visible && (
            <div
              className="z-9999 bg-email text-center lg:hidden text-white right-0 font-bold border border-1 border-home absolute"
              style={{ fontSize: "10px", width: "212px" }}
            >
              <Link to="">
                <div
                  className={
                    activetab == "Home" ? "text-navbarBlue bg-white" : ""
                  }
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setActivetab("Home");
                    setVisible(false);
                  }}
                >
                  HOME
                </div>
              </Link>
              <Link to="/features">
                <div
                  className={
                    activetab == "Features" ? "text-navbarBlue bg-white" : ""
                  }
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setActivetab("Features");
                    setVisible(false);
                  }}
                >
                  FEATURES
                </div>
              </Link>
              <Link to="/pos">
                <div
                  className={
                    activetab == "Pos" ? "text-navbarBlue bg-white" : ""
                  }
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setActivetab("Pos");
                    setVisible(false);
                  }}
                >
                  POS
                </div>
              </Link>
              {/* <Link to="/pricing"><div className={activetab == 'Pricing' ? 'text-navbarBlue bg-white' : ''} style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => {
                                setActivetab('Pricing')
                                setVisible(false)
                            }}>PRICING</div></Link> */}
              <Link to="/services">
                <div
                  className={
                    activetab == "Services" ? "text-navbarBlue bg-white" : ""
                  }
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setActivetab("Services");
                    setVisible(false);
                  }}
                >
                  SERVICES
                </div>
              </Link>
              <Link to="/about">
                <div
                  className={
                    activetab == "About" ? "text-navbarBlue bg-white" : ""
                  }
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setActivetab("About");
                    setVisible(false);
                  }}
                >
                  ABOUT US
                </div>
              </Link>
              <Link to="/contact">
                <div
                  className={
                    activetab == "Contact" ? "text-navbarBlue bg-white" : ""
                  }
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setActivetab("Contact");
                    setVisible(false);
                  }}
                >
                  CONTACT US
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Navbar;
