import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import home from "../assets/HomeBanner.svg";
import { FaPlayCircle } from "react-icons/fa";
import desktop from "../assets/desktop.svg";
import web from "../assets/web.svg";
import mobile from "../assets/mobile.svg";
import Benefits from "../components/Benefits";
import Tool from "../components/Tool";
import barcode from "../assets/barcode.svg";
import quotations from "../assets/quotations.svg";
import invoices from "../assets/invoice.svg";
import sms from "../assets/sms.svg";
import outstandings from "../assets/outstandings.svg";
import analytics from "../assets/analytics.svg";
import documents from "../assets/documents.svg";
import exp from "../assets/import.svg";
import client from "../assets/client.svg";
import multiuser from "../assets/multiuser.svg";
import propix from "../assets/Propix.svg";
import katara from "../assets/KataraDental.svg";
import almas from "../assets/almas.svg";
import uniqueAcademy from "../assets/uniqueAcademy.svg";
import imtiyaz from "../assets/imtiyaz.svg";
import acekudale from "../assets/acekudale.svg";
import suman from "../assets/svman.svg";
import tirupati from "../assets/truptiMarketing.svg";
import pesco from "../assets/pesco.svg";
import list from "../assets/list.svg";
import jinkosolar from "../assets/jinkosolar.svg";
import solplanet from "../assets/solplanet.svg";
import feat4 from "../assets/4features.svg";
import msg from "../assets/message.svg";
import Keyfeat from "../components/Keyfeat";
import education from "../assets/educataion.svg";
import law from "../assets/law.svg";
import ecommerce from "../assets/ecommerce.svg";
import medical from "../assets/medical.svg";
import textile from "../assets/textile.svg";
import supply from "../assets/supplychain.svg";
import tourism from "../assets/tourism.svg";
import retail from "../assets/retail.svg";
import Numinterest from "../components/Numinterest";
import Pricecard from "../components/Pricecard";
import { Link } from "react-router-dom";
import bg from "../assets/Background.svg";
import bg2 from "../assets/bg-2.svg";
import bg3 from "../assets/Background2.svg";
import features from "../assets/features.svg";
import Poscomp from "../components/Poscomp";
import BottomLine from "../components/BottomLine";

const Home = () => {
  const [feat, setFeat] = useState("Masters");
  return (
    <div>
      <img src={bg3} className="absolute top-0 z-0 w-full lg:hidden" />
      <img
        src={bg}
        className="absolute top-0 right-0 z-0 hidden lg:block w-8/12"
      />
      <div className="lg:flex relative lg:mt-20 mt-20 lg:mx-[55px] items-center justify-center">
        <img src={home} className="mt-4 mx-auto w-10/12 lg:hidden relative" />
        <div className="lg:mr-9 text-center lg:text-left lg:w-6/12 flex flex-col items-center lg:items-start gap-4 lg:gap-2 mt-10 lg:mt-0 flex-1">
          <p className="text-[24px] lg:text-[40px] font-bold leading-[28px] lg:leading-[48px] ">
            Empower Your Business with<br></br>
            <span className=" text-home">ERP's Seamless Efficiency</span>
          </p>
          <BottomLine />
          <p className="w-[353px] lg:w-full text-[13px] lg:text-[16px] leading-[22px] lg:leading-[24px] mb-5 font-thin text-center lg:text-left">
            Make your Business grow with our easy-to-use ERP tools, ensuring
            Scalability and Lasting success. From user-friendly interfaces to
            swift and Responsive Services, we Prioritise Efficiency to ensure a
            productive experience for our Consumers. Embark on a journey of
            sustained growth as our user-centric ERP tools adapt to your
            Dynamic Business Needs.
          </p>
          <div className="flex">
            <button className="h-12 bg-gradient-to-b from-home to-linear border rounded-md text-white  mx-auto lg:mx-0 w-[114px] lg:w-[160px] text-[9.99px] lg:text-[14px] leading-[34px] lg:leading-[48px] font-bold transition-all duration-300 ease glow-on-hover hover:text-home hover:from-white hover:to-white hover:border-border">
              GET STARTED
            </button>
            <div className="glow-on-hover hidden lg:block h-12 border border-border rounded-md border-2 ml-4 w-[207px] cursor-pointer">
              <div className="flex w-max mx-auto h-12">
                <FaPlayCircle
                  color="#019DEA"
                  fontSize="1.4em"
                  className="my-auto mr-2 "
                />
                <div className="my-auto text-border hover-border-white">
                  WATCH VIDEO
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={home} className="w-6/12 hidden lg:block" />
      </div>

      <div
        className="xl:flex justify-between py-5 mt-5 mx-auto"
        style={{ maxWidth: "1500px" }}
      >
        <div className="xl:flex w-full xl:mx-10 justify-between mt-20">
          <div
            className="order-3 xl:w-full xl:ml-10 flex flex-col justify-center gap-6 xl:text-left text-center"
            style={{ marginRight: "55px", marginLeft: "55px" }}
          >
            <p className="font-bold text-[24px] leading-[28.81px] lg:text-[48px] lg:leading-[57.6px]">
              Seamless{" "}
              <span className=" text-email">
                Cross-<br></br>Platform Accessibility
              </span>
              .
            </p>
            <BottomLine />
            <p className="font-normal text-[13px] leading-[22px] lg:text-[18px] lg:leading-[32px] text-center lg:text-left">
              One platform, Endless Possibilities. Manage your tasks Flawlessly
              with our Intuitive Desktop app as here you get everything you need
              in one Seamless Experience. From Mobile application to Web
              application, stay connected on the Go with our mobile app which
              Empowers you to achieve more, wherever you are.
            </p>
            <button className="bg-download px-4 py-2 rounded-md text-white text-sm w-max xl:mx-0 mx-auto mb-10 xl:mb-0 transition-all duration-300 ease glow-on-hover hover:text-home hover:bg-white hover:border-border">
              DISCOVER MORE
            </button>
          </div>
          <div className="hidden lg:flex w-max mx-auto">
            <div className="order-1 lg:flex justify-between xl:w-full xl:block xl:ml-20 xl:pl-2">
              <div className=" text-center bg-white rounded-md lg:mr-10 mb-10 lg:mb-0 xl:w-full p-[50px] box-shadow glow-on-hover hover:scale-105">
                <img src={desktop} className="mx-auto"></img>
                <div className="font-bold text-2xl mt-2">
                  Desktop<br></br>Application
                </div>
                <button className=" bg-download px-4 py-2 rounded-md text-white w-full text-sm mt-8">
                  Download Now
                </button>
              </div>
              <div className="text-center bg-white rounded-md xl:mt-7 xl:w-full p-[50px] box-shadow glow-on-hover hover:scale-105">
                <img src={mobile} className="mx-auto"></img>
                <div className="font-bold text-2xl mt-2">
                  Mobile<br></br>Application
                </div>
                <button className="bg-download px-4 py-2 rounded-md text-white w-full text-sm mt-8">
                  Download Now
                </button>
              </div>
            </div>
            <div className="w-full mx-auto flex flex-col justify-center order-2 lg:ml-10 xl:w-full xl:ml-10 lg:mt-0 mt-10">
              <div className=" text-center bg-white rounded-md p-[50px] box-shadow glow-on-hover hover:scale-105">
                <img src={web} className="mx-auto"></img>
                <div className="font-bold text-2xl mt-2">
                  Web<br></br>Application
                </div>
                <button className="bg-download px-4 py-2 rounded-md text-white w-full text-sm mt-8">
                  Sign Up Now
                </button>
              </div>
            </div>
          </div>
          <div className="flex gap-4 lg:hidden flex-wrap items-center justify-center">
            <div
              className="bg-white rounded-md w-[111px] h-[138px] flex flex-col items-center justify-center gap-2"
              style={{
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
              }}
            >
              <img src={desktop} className="mx-auto w-[40px] h-[40px]" />
              <p className="font-bold text-[9px] leading-[11px] text-center">
                Desktop<br></br>Application
              </p>
              <button className=" bg-download rounded-md py-2 text-white w-[80%] text-[7px] leading-[11px]">
                Download Now
              </button>
            </div>
            <div
              className="bg-white rounded-md w-[111px] h-[138px] flex flex-col items-center justify-center gap-2"
              style={{
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
              }}
            >
              <img src={mobile} className="mx-auto w-[40px] h-[40px]" />
              <p className="font-bold text-[9px] leading-[11px] text-center">
                Mobile<br></br>Application
              </p>
              <button className=" bg-download rounded-md py-2 text-white w-[80%] text-[7px] leading-[11px]">
                Download Now
              </button>
            </div>

            <div
              className="bg-white rounded-md w-[111px] h-[138px] flex flex-col items-center justify-center gap-2"
              style={{
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
              }}
            >
              <img src={web} className="mx-auto w-[40px] h-[40px]" />
              <p className="font-bold text-[9px] leading-[11px] text-center">
                Web<br></br>Application
              </p>
              <button className=" bg-download rounded-md py-2 text-white w-[80%] text-[7px] leading-[11px]">
                Download Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-b from-home to-trans mt-10">
        <div
          className="bg-linear w-full py-10 flex flex-col items-center justify-center gap-2 lg:gap-4"
          style={{
            borderBottomLeftRadius: "40%",
            borderBottomRightRadius: "40%",
          }}
        >
          <p className="font-bold text-white text-[28px] lg:text-[48px] leading-[57px] text-center">
            Benefits of ABS ERP Software
          </p>
          <p className="text-white text-center text-[13px] lg:text-[20px] leading-[20px] lg:leading-[32px] font-medium lg:font-normal max-w-[352px] lg:max-w-[1112px]">
            Experience Streamlined Operations, Precise Data Management, and
            Informed Decision-Making for unparalleled growth. Transform your
            Business landscape with our Comprehensive ERP Solutions.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 sm:px-5 px-0 mx-auto pb-10 max-w-[332px] md:max-w-[1200px]">
          <Benefits
            title="Real-time Reports"
            value="ABS ERP gives you fast and accurate reports, helping your business run smoothly and make smart decisions, thus supporting you in every step of the way."
          />
          <Benefits
            title="Cost-effective Solution"
            value="ABS ERP is the affordable solution that fits your business perfectly. Eliminate the need for expensive software and get the most value for your investment."
          />
          <Benefits
            title="Instant Access"
            value="Stop wasting time hunting for data. Get instant access to everything you need and boost your productivity, making the most of every minute. "
          />
          <Benefits
            title="Paperless System"
            value="Eliminate manual errors and ensure data consistency with secure cloud storage thus saving you time and minimizing errors."
          />
          <Benefits
            title="Easy to Use"
            value="Our easy-to-use interface makes it simple for you to handle tasks smoothly, giving you the power to navigate and manage things with ease."
          />
          <Benefits
            title="Privacy and Security"
            value="We  have a strict privacy policy that ensures your information is protected against unauthorized access.  Trust us for a secure digital environment."
          />
        </div>
      </div>

      {/* <Poscomp /> */}

      <div className="text-center flex flex-col items-center justify-center gap-4 mt-10">
        <p className="font-bold text-black text-[28px] lg:text-[48px] leading-[24px] md:leading-[57px] text-center">
          ABS ERP <span className=" text-home">Tool Features</span>
        </p>
        <BottomLine />
        <p className="text-black text-center text-[13px] lg:text-[20px] leading-[20px] lg:leading-[32px] font-medium lg:font-normal max-w-[352px] lg:max-w-[1112px]">
          Discover the Power of <span className="font-bold">ABS ERP</span> Tool
          Features – A comprehensive suite Tailored to Enhance Operational
          Efficiency, Improve Accuracy, and Elevate your{" "}
          <span className="font-bold">ERP</span> management capabilities.
        </p>

        <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 pb-5 px-5 my-5 md:my-10 justify-items-center max-w-screen-lg mx-auto">
          <Tool img={barcode} title="Barcode Support" />
          <Tool img={quotations} title="Quotations" />
          <Tool img={invoices} title="Professional Invoices" />
          <Tool img={sms} title="SMS Notifications" />
          <Tool img={outstandings} title="Outstandings" />
          <Tool img={analytics} title="Reports and Analytics" />
          <Tool img={documents} title="Documents" />
          <Tool img={exp} title="Import/Export " />
          <Tool img={client} title="Mobile, Web and Windows Client" />
          <div className="block lg:hidden">
            <Tool invisible />
          </div>
          <Tool img={multiuser} title="Multi User" />
          <div className="block lg:hidden">
            <Tool invisible />
          </div>
        </div>
      </div>

      <Numinterest />

      {/* <div className="text-center my-20 flex flex-col items-center justify-center gap-4">
        <p className="font-bold text-black text-[28px] lg:text-[48px] leading-[24px] md:leading-[57px] text-center">
          Pricing Plans
        </p>
        <BottomLine />
        <p className="text-black text-center text-[13px] lg:text-[20px] leading-[20px] lg:leading-[32px] font-medium lg:font-normal max-w-[352px] lg:max-w-[1112px]">
          Tailor your success with flexible and transparent ABS ERP Pricing
          Plans designed to match your business needs – choose affordability
          without compromising on powerful features.
        </p>

        <div className="w-full mx-auto mt-4">
          <div className="flex justify-center">
            <div
              className="grid lg:grid-cols-3 gap-8 w-max "
              style={{ alignItems: "center" }}
            >
              <Pricecard
                title="Start-UP"
                amount={15}
                sub="PER MONTH"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lorem nunc, semper sed metus at, lacinia viverra leo."
                line1="Easy Customizable"
                line2="1 Month Support Service"
                line3="Singal User license"
              />
              <Pricecard
                title="Advanced"
                amount={30}
                sub="PER MONTH"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lorem nunc, semper sed metus at, lacinia viverra leo."
                line1="Easy Customizable"
                line2="1 Year Support Service"
                line3="3 User license"
                special={true}
              />
              <Pricecard
                title="Premium"
                amount={50}
                sub="PER MONTH"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lorem nunc, semper sed metus at, lacinia viverra leo."
                line1="Easy Customizable"
                line2="1 Year Support Service"
                line3="10 User license"
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className="bg-white">
        <p className="font-bold text-navbarBlue text-center py-5 lg:py-10 text-[30px] leading-[41px] md:text-[40px] md:leading-[57px]">
          Our Clients
        </p>
        <div className="xl:flex gap-5 items-center justify-center w-max mx-auto mb-4 hidden ">
          <div className="bg-white rounded-md mx-2 flex items-center w-3/12 px-2 py-1 hover:scale-125 tansition-all duration-300 ease">
            <img src={propix} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md mx-2 flex items-center w-3/12 px-2 py-1 hover:scale-125 tansition-all duration-300 ease">
            <img src={katara} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md mx-2 flex items-center w-3/12 px-2 py-1 hover:scale-125 tansition-all duration-300 ease">
            <img src={almas} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md mx-2 flex items-center w-3/12 px-2 py-1 hover:scale-125 tansition-all duration-300 ease">
            <img src={uniqueAcademy} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md mx-2 flex items-center w-3/12 px-2 py-1 hover:scale-125 tansition-all duration-300 ease">
            <img src={imtiyaz} className="mx-auto"></img>
          </div>
        </div>
        <div className="xl:flex gap-5 items-center justify-center w-max mx-auto pb-10 hidden">
          <div className="bg-white rounded-md mx-2 flex items-center w-2/12 px-2 py-1 hover:scale-125 tansition-all duration-300 ease">
            <img src={acekudale} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md mx-2 flex items-center w-2/12 px-2 py-1 hover:scale-125 tansition-all duration-300 ease">
            <img src={tirupati} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md mx-2 flex items-center w-2/12 px-2 py-1 hover:scale-125 tansition-all duration-300 ease">
            <img src={pesco} className="mx-auto"></img>
          </div>
        </div>
        <div className=" xl:hidden pb-10 flex flex-wrap gap-3 gap-y-10 items-center justify-center mx-5">
          <div className="bg-white rounded-md flex items-center w-[101px] h-[40px] px-2 py-1">
            <img src={propix} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md  flex items-center w-[101px] h-[40px] px-2 py-1">
            <img src={katara} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md  flex items-center w-[101px] h-[40px] px-2 py-1">
            <img src={almas} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md  flex items-center w-[101px] h-[40px] px-2 py-1">
            <img src={uniqueAcademy} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md  flex items-center w-[101px] h-[40px] px-2 py-1">
            <img src={imtiyaz} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md flex items-center w-[101px] h-[40px] px-2 py-1">
            <img src={acekudale} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md  flex items-center w-[101px] h-[40px] px-2 py-1">
            <img src={tirupati} className="mx-auto"></img>
          </div>
          <div className="bg-white rounded-md  flex items-center w-[101px] h-[40px] px-2 py-1">
            <img src={pesco} className="mx-auto"></img>
          </div>
        </div>
      </div>

      <div className="my-10 mx-5 lg:mx-20 lg:py-10 lg:px-10 lg:text-left text-center">
        <p className="font-bold text-[24px] leading-[57px] lg:text-[48px]">
          Our Exciting Features <span className="text-email">For You</span>
        </p>
        <BottomLine />
        <p className="mb-5 text-tools2 lg:w-8/12 text-[13px] leading-[20px] lg:text-[20px] lg:leading-[32px]">
          Discover tailored excellence: Unleash the power of our exciting
          features designed to elevate your experience and drive success.
        </p>
        <div className="lg:mx-0">
          <img src={features} className="mx-auto lg:hidden mb-10" />
          <ul
            className="flex justify-between lg:justify-normal font-bold"
            style={{ fontSize: "18px" }}
          >
            <Link to="/" onClick={() => setFeat("Masters")}>
              <li className="text-center">
                <div
                  className={`${
                    feat === "Masters" ? "text-home" : ""
                  } w-full sm:w-32 `}
                >
                  Masters
                </div>
                {feat === "Masters" && (
                  <div
                    className="bg-home mt-5 w-full sm:w-full"
                    style={{ height: "4px" }}
                  ></div>
                )}
              </li>
            </Link>
            <Link to="/sales" onClick={() => setFeat("Sales")}>
              <li className="text-center">
                <div
                  className={`${
                    feat === "Sales" ? "text-home" : ""
                  } w-full sm:w-32 `}
                >
                  Sales
                </div>
                {feat === "Sales" && (
                  <div
                    className="bg-home mt-5 w-full sm:w-full"
                    style={{ height: "4px" }}
                  ></div>
                )}
              </li>
            </Link>
            <Link to="/purchase" onClick={() => setFeat("Purchase")}>
              <li className="text-center">
                <div
                  className={`${
                    feat === "Purchase" ? "text-home" : ""
                  } w-full sm:w-32 `}
                >
                  Purchase
                </div>
                {feat === "Purchase" && (
                  <div
                    className="bg-home mt-5 w-full sm:w-full"
                    style={{ height: "4px" }}
                  ></div>
                )}
              </li>
            </Link>
            <Link to="/reports" onClick={() => setFeat("Reports")}>
              <li className="text-center">
                <div
                  className={`${
                    feat === "Reports" ? "text-home" : ""
                  } w-full sm:w-32 `}
                >
                  Reports
                </div>
                {feat === "Reports" && (
                  <div
                    className="bg-home mt-5 w-full sm:w-full"
                    style={{ height: "4px" }}
                  ></div>
                )}
              </li>
            </Link>
          </ul>
        </div>

        <hr className="mb-10"></hr>
        <Outlet />
      </div>

      <div
        style={{ backgroundImage: `url(${bg2})`, backgroundSize: "cover" }}
        className="pb-10"
      >
        <p className="text-white font-bold text-center pt-10 mb-2 text-[28px] leading-[57px] lg:text-[32px] lg:leading-[38px]">
          Key Features
        </p>
        <p className="text-center text-white text-[14px] leading-[20px] lg:text-[20px] lg:leading-[24px] mb-10 mx-5 lg:mx-10">
          ABS ERP Key Features Empowers Your Business with a Suite of Advanced
          Tool
        </p>
        <img src={feat4} className=" mx-auto mb-10 lg:hidden w-10/12"></img>
        <div className="flex gap-3 justify-between mx-5 lg:mx-auto text-white">
          <div className="lg:w-4/12 lg:mr-5 flex-1 flex flex-col items-center justify-center">
            <Keyfeat
              img={msg}
              title="Invoicing and Billing"
              content="Simplify your financial transactions with ease using our Invoicing and Billing feature, ensuring smooth and accurate financial management for your business."
            />
            <Keyfeat
              img={msg}
              title="Sales & Purchase"
              content="Elevate your business journey with integrated Sales & Purchase features, ensuring seamless transactions from lead to procurement."
            />
          </div>
          <img src={feat4} className="w-5/12 hidden lg:block"></img>
          <div className="lg:w-4/12 lg:ml-5 flex-1 flex flex-col items-center justify-center">
            <Keyfeat
              img={msg}
              title="Inventory Management"
              content="Optimize stock control effortlessly with our Inventory Management Features, providing real-time insights and efficient control over your business's supply chain"
            />
            <Keyfeat
              img={msg}
              title="Production"
              content="Optimize production efficiency with our ERP tool's dynamic features, empowering users to plan, track, and minimize time wastage for streamlined operations."
            />
          </div>
        </div>
      </div>

      <div className="py-20 flex flex-col items-center justify-center gap-5">
        <p className="font-bold text-[28px] lg:text-[48px] leading-[24px] md:leading-[57px] text-center">
          Industries We <span className="text-email">Serve In</span>
        </p>
        <BottomLine />
        <p className="text-center text-[13px] lg:text-[20px] leading-[20px] lg:leading-[32px] font-medium lg:font-normal max-w-[352px] lg:max-w-[1112px]">
          From Manufacturing to Healthcare, our Applications cater to your
          unique needs, Amplifying operational efficiency and ensuring success
          in every sector.
        </p>
        <div
          className="lg:grid lg:grid-cols-4 flex items-center justify-center flex-wrap gap-3 text-center mx-10 font-semibold lg:mx-auto"
          style={{ maxWidth: "1100px" }}
        >
          <Tool img={education} title="Education & Training" />
          <Tool img={law} title="Law Firms" />
          <Tool img={ecommerce} title="E-Commerce" />
          <Tool img={medical} title="Medical Industry" />
          <Tool img={textile} title="Textile Industry" />
          <Tool img={supply} title="Supply Chain Management" />
          <Tool img={tourism} title="Tourism Industry" />
          <Tool img={retail} title="Retail Industry" />
        </div>
      </div>

      <div className="lg:flex block justify-between bg-gradient-to-b from-home to-linear text-white mb-10 px-5 lg:px-20 py-20">
        <div className="lg:w-5/12 text-center lg:text-left">
          <p className="text-[30px] leading-[41px] lg:text-[48px] lg:leading-[57px] font-bold mb-5 ">
            Start Using ABS ERP Now
          </p>
          <p className="text-[13px] leading-[20px] lg:text-[20px] lg:leading-[32px]">
            With Integrated Solution which can take your business to a next
            level with advance ERP service{" "}
          </p>
          <div className="lg:flex mt-5 justify-center lg:justify-normal hidden">
            <button
              className="h-12 bg-white rounded-md text-linear hover:bg-home hover:text-white glow-on-hover border hover:border-white border-transparent border-2 transition-all duration-300 ease"
              style={{ width: "160px" }}
            >
              GET STARTED
            </button>
            <div className=" h-12 w-[207px] border border-white rounded-md border-2 ml-4 cursor-pointer">
              <div className="flex w-max mx-auto h-12">
                <FaPlayCircle
                  color="white"
                  fontSize="1.4em"
                  className="my-auto mr-2"
                />
                <div className="my-auto text-white">WATCH VIDEO</div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-5/12 lg:mt-0 mt-5 flex items-center">
          <ul>
            <li className="flex items-start my-3">
              <img
                src={list}
                className="mr-2"
                style={{ marginTop: "7px" }}
              ></img>
              Experience a business where user-friendliness remains at the
              forefront.
            </li>
            <li className="flex items-start my-3">
              <img
                src={list}
                className="mr-2"
                style={{ marginTop: "7px" }}
              ></img>
              Stay ahead of the pack with our cutting-edge reporting system.
            </li>
            <li className="flex items-start my-3">
              <img
                src={list}
                className="mr-2"
                style={{ marginTop: "7px" }}
              ></img>
              Experience the power of affordability with our cost-effective
              solutions.
            </li>
            <li className="flex items-start my-3">
              <img
                src={list}
                className="mr-2"
                style={{ marginTop: "7px" }}
              ></img>
              Boost your productivity with our seamless and efficient workflows.
            </li>
            <li className="flex items-start my-3">
              <img
                src={list}
                className="mr-2"
                style={{ marginTop: "7px" }}
              ></img>
              Stay ahead of the curve with our fast and reliable SMS alerts.
            </li>
          </ul>
        </div>
        <div className="lg:hidden mt-5 justify-center lg:justify-normal flex">
          <button
            className="h-12 bg-white rounded-md text-linear"
            style={{ width: "160px" }}
          >
            GET STARTED
          </button>
          <div
            className=" h-12 border border-white rounded-md border-2 ml-4"
            style={{ width: "207px" }}
          >
            <div className="flex w-max mx-auto h-12">
              <FaPlayCircle
                color="white"
                fontSize="1.4em"
                className="my-auto mr-2"
              />
              <div className="my-auto text-white">WATCH VIDEO</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
