import { Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Services from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Features from "./pages/Features";
import Masters from "./components/Masters";
import Sales from "./components/Sales";
import Purchase from "./components/Purchase";
import Reports from "./components/Reports";
import Pos from "./pages/Pos";
import { useEffect, useState } from "react";
import Loader from "./components/Loader";
function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="App">
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Landing />}>
          <Route exact path="" element={<Home />}>
            <Route exact path="" element={<Masters />}></Route>
            <Route path="sales" element={<Sales />}></Route>
            <Route path="purchase" element={<Purchase />}></Route>
            <Route path="reports" element={<Reports />}></Route>
          </Route>
          <Route path="features" element={<Features />}></Route>
          {/* <Route path="pricing" element={<Pricing/>}></Route> */}
          <Route path="services" element={<Services />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="about" element={<About />}></Route>
          <Route path="pos" element={<Pos />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
