import React, { useState } from "react";
import { TiTick } from "react-icons/ti";
import img1 from "../assets/InventoryMngErp.svg";
import img2 from "../assets/SalesErp.svg";
import img3 from "../assets/ProductionErp.svg";
import Numinterest from "../components/Numinterest";
import barcode from "../assets/barcode.svg";
import quotations from "../assets/quotations.svg";
import invoices from "../assets/invoice.svg";
import sms from "../assets/sms.svg";
import outstandings from "../assets/outstandings.svg";
import analytics from "../assets/analytics.svg";
import documents from "../assets/documents.svg";
import exp from "../assets/import.svg";
import client from "../assets/client.svg";
import multiuser from "../assets/multiuser.svg";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import BottomLine from "../components/BottomLine";
const Features = () => {
  const [page, setPage] = useState(0);
  const [display, setDisplay] = useState(false);
  const [val, setVal] = useState("MORE");
  const load = () => {
    setDisplay(!display);
    if (val == "MORE") setVal("LESS");
    else setVal("MORE");
  };
  return (
    <div className="my-5 lg:my-10 overflow-x-hidden ">
      <div className="order-3 flex flex-col items-center justify-center gap-4 text-center">
        <p className="font-bold text-[24px] leading-[28.81px] lg:text-[48px] lg:leading-[57.6px]">
          ERP Features
        </p>
        <BottomLine />
        <p className="font-normal text-[13px] leading-[22px] lg:text-[18px] lg:leading-[32px] text-center lg:text-left">
          A comprehensive suite tailored to enhance Operational Efficiency,
          Improve Accuracy, and Elevate your ERP management capabilities.
        </p>
      </div>
      <div className="block lg:flex mx-10 my-10 lg:my-20 justify-between">
        <img src={img1} className="w-9/12 lg:hidden mx-auto mb-5"></img>
        <div className="w-full mr-10">
          <p className="text-[22px] leading-[38px] lg:text-[45px] lg:leading-[54px] font-semibold">
            Inventory Management
          </p>
          <p className="my-3 font-light text-[#5E6075] text-[13px] leading-[24px] lg:text-[24px] lg:leading-[38px]">
            From Forecasting trends to Optimizing deliveries, our Inventory
            Management works on ensuring smooth operations and satisfied
            Consumers through Some of the following features:{" "}
          </p>
          <div style={{ fontSize: "20px" }}>
            <div className="flex my-4">
              <TiTick className="my-auto mr-1" color="#FF825C" />
              <div className="text-[13px] leading-[30px] lg:text-[20px] lg:leading-[30px]">
                Seamless barcode tracking
              </div>
            </div>
            <div className="flex mb-4">
              <TiTick className="my-auto mr-1" color="#FF825C" />
              <div className="text-[13px] leading-[30px] lg:text-[20px] lg:leading-[30px]">
                Availability of multiple stores on you finger tips
              </div>
            </div>
            <div className="flex mb-4">
              <TiTick className="my-auto mr-1" color="#FF825C" />
              <div className="text-[13px] leading-[30px] lg:text-[20px] lg:leading-[30px]">
                Effortless automates stock adjustment
              </div>
            </div>
          </div>
          <button className="text-base bg-email text-white mt-3 px-4 py-3 rounded-3xl border-2 border-transparent glow-on-hover hover:text-navbarBlue hover:bg-white hover:border-border">
            Get Started With ERP Solutions
          </button>
        </div>
        <img src={img1} className="hidden lg:block w-1/2 flex-1"></img>
      </div>
      <div className="block lg:flex mx-10 my-20 justify-between">
        <img className="lg:w-1/2 lg:mr-10 mx-auto lg:mx-0 w-9/12 " src={img2} />
        <div className="w-full mr-10 my-5 lg:my-0 lg:w-1/2 flex-1">
          <p className="text-[22px] leading-[38px] lg:text-[45px] lg:leading-[54px] font-semibold">
            Sales & Purchase
          </p>
          <p className="my-3 font-light text-[#5E6075] text-[13px] leading-[24px] lg:text-[24px] lg:leading-[38px]">
            ABS ERP manage every step from Selling to Sourcing to ensure you
            find the Perfect Balance for Optimal Growth. Turn every transaction
            into Success through these features and much more!
          </p>
          <div style={{ fontSize: "20px" }}>
            <div className="flex my-4">
              <TiTick className="my-auto mr-1" color="#FF825C" />
              <div className="text-[13px] leading-[30px] lg:text-[20px] lg:leading-[30px]">
                Generate bills effortlessly through E-way bill
              </div>
            </div>
            <div className="flex mb-4">
              <TiTick className="my-auto mr-1" color="#FF825C" />
              <div className="text-[13px] leading-[30px] lg:text-[20px] lg:leading-[30px]">
                Receive debit/credit note always on time
              </div>
            </div>
            <div className="flex mb-4">
              <TiTick className="my-auto mr-1" color="#FF825C" />
              <div className="text-[13px] leading-[30px] lg:text-[20px] lg:leading-[30px]">
                Order confirmation
              </div>
            </div>
          </div>
          <button className="text-base bg-email text-white mt-3 px-4 py-3 rounded-3xl border-2 border-transparent glow-on-hover hover:text-navbarBlue hover:bg-white hover:border-border">
            Get Started With ERP Solutions
          </button>
        </div>
      </div>
      <div className="block lg:flex mx-10 my-10 lg:my-20 justify-between">
        <img src={img3} className="w-9/12 lg:hidden mx-auto mb-5"></img>
        <div className="w-full mr-10">
          <p className="text-[22px] leading-[38px] lg:text-[45px] lg:leading-[54px] font-semibold">
            Production
          </p>
          <p className="my-3 font-light text-[#5E6075] text-[13px] leading-[24px] lg:text-[24px] lg:leading-[38px]">
            We craft excellence in every step of the production process to cater
            to your vision of Speed, Quality and Reliability. Unleash the
            potential of our production function through following aspects and
            more!
          </p>
          <div style={{ fontSize: "20px" }}>
            <div className="flex my-4">
              <TiTick className="my-auto mr-1" color="#FF825C" />
              <div className="text-[13px] leading-[30px] lg:text-[20px] lg:leading-[30px]">
                Integrated line rejection tracking system
              </div>
            </div>
            <div className="flex mb-4">
              <TiTick className="my-auto mr-1" color="#FF825C" />
              <div className="text-[13px] leading-[30px] lg:text-[20px] lg:leading-[30px]">
                Multi level bill of materials
              </div>
            </div>
            <div className="flex mb-4">
              <TiTick className="my-auto mr-1" color="#FF825C" />
              <div className="text-[13px] leading-[30px] lg:text-[20px] lg:leading-[30px]">
                Production Order
              </div>
            </div>
          </div>
          <button className="text-base bg-email text-white mt-3 px-4 py-3 rounded-3xl border-2 border-transparent glow-on-hover hover:text-navbarBlue hover:bg-white hover:border-border">
            Get Started With ERP Solutions
          </button>
        </div>
        <img src={img3} className="hidden lg:block w-1/2 flex-1"></img>
      </div>
      <Numinterest />
      <div className="text-center flex flex-col items-center justify-center gap-4 my-10 lg:my-20">
        <p className="font-bold text-black text-[28px] lg:text-[48px] leading-[24px] md:leading-[57px] text-center">
          ABS ERP <span className=" text-home">Tool Features</span>
        </p>
        <BottomLine />
        <p className="text-black text-center text-[13px] lg:text-[20px] leading-[20px] lg:leading-[32px] font-medium lg:font-normal max-w-[352px] lg:max-w-[1112px]">
          Discover the Power of <span className="font-bold">ABS ERP</span> Tool
          Features – A comprehensive suite Tailored to Enhance Operational
          Efficiency, Improve Accuracy, and Elevate your{" "}
          <span className="font-bold">ERP</span> management capabilities.
        </p>
        <div className="flex mx-5 lg:mx-10">
          <IoIosArrowBack
            onClick={() => {
              if (page == 1) setPage(0);
            }}
            fontSize="3rem"
            className="mr-5 my-auto hidden lg:block"
          />
          {page == 0 ? (
            <div>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-3">
                <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col justify-center px-5 shadow-xl">
                  <div className="flex mb-3">
                    <img
                      src={invoices}
                      className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                    ></img>
                    <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                      Professional<br></br>Invoices
                    </div>
                  </div>
                  <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                    Ensuring accurate, Quality Invoices for Quick Payments,
                    Compliance with well Organized Financial Management.
                  </div>
                </div>
                <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                  <div className="flex mb-3">
                    <img
                      src={sms}
                      className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                    ></img>
                    <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                      SMS <br></br>Notifications
                    </div>
                  </div>
                  <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                    Enhance Communication by sending Real Time Alters though SMS
                    for Fostering Quicker Responses and Support.
                  </div>
                </div>
                <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                  <div className="flex mb-3">
                    <img
                      src={quotations}
                      className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                    ></img>
                    <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                      Quotations
                    </div>
                  </div>
                  <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                    Providing users with a tool to Generate, Customize, and
                    Track Quotes Efficiently with out Quotations Feature.
                  </div>
                </div>
                <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                  <div className="flex mb-3">
                    <img
                      src={analytics}
                      className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                    ></img>
                    <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                      Report And Analytics
                    </div>
                  </div>
                  <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                    Allowing users to Track Performance and Empowers them with
                    Insightful data Visualizations and detailed reports.
                  </div>
                </div>
                <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                  <div className="flex mb-3">
                    <img
                      src={barcode}
                      className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                    ></img>
                    <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                      Barcode Support
                    </div>
                  </div>
                  <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                    Speeding up processes by scanning the Barcodes which
                    improves efficiency, reduces errors, and enhances supply
                    chain.{" "}
                  </div>
                </div>
                <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                  <div className="flex mb-3">
                    <img
                      src={multiuser}
                      className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                    ></img>
                    <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                      Multi Users
                    </div>
                  </div>
                  <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                    Get access for Multiple Users at a single, fostering
                    collaborative work environment with better coordination.
                  </div>
                </div>
              </div>
              {display && (
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-3 mt-3">
                  <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                    <div className="flex mb-3">
                      <img
                        src={exp}
                        className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                      ></img>
                      <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                        Import/ Export
                      </div>
                    </div>
                    <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                      Get Seamless Data Exchange, enabling users import
                      information into the system or export data for external
                      use.
                    </div>
                  </div>
                  <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                    <div className="flex mb-3">
                      <img
                        src={outstandings}
                        className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                      ></img>
                      <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                        Outstanding
                      </div>
                    </div>
                    <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                      Get a clear view of Pending Payments and Unresolved task
                      which help Firms to work with full efficiency.{" "}
                    </div>
                  </div>
                  <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                    <div className="flex mb-3">
                      <img
                        src={documents}
                        className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                      ></img>
                      <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                        Documents
                      </div>
                    </div>
                    <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                      providing users with a structured repository for Easy
                      Access and Sharing Files which Increase Firm Efficiency.
                    </div>
                  </div>
                  <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                    <div className="flex mb-3">
                      <img
                        src={client}
                        className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                      ></img>
                      <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                        Mobile, Web And Windows Client
                      </div>
                    </div>
                    <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                      This Feature Allow users interact seamlessly with the
                      system across various platforms as per there convenience.
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="grid grid-cols-3 gap-3">
              <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col justify-center px-5 shadow-xl">
                <div className="flex mb-3">
                  <img
                    src={exp}
                    className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                  ></img>
                  <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                    Import/ Export
                  </div>
                </div>
                <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                  Get Seamless Data Exchange, enabling users import information
                  into the system or export data for external use.
                </div>
              </div>
              <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                <div className="flex mb-3">
                  <img
                    src={outstandings}
                    className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                  ></img>
                  <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                    Outstanding
                  </div>
                </div>
                <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                  Get a clear view of Pending Payments and Unresolved task which
                  help Firms to work with full efficiency.{" "}
                </div>
              </div>
              <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                <div className="flex mb-3">
                  <img
                    src={documents}
                    className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                  ></img>
                  <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                    Documents
                  </div>
                </div>
                <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                  providing users with a structured repository for Easy Access
                  and Sharing Files which Increase Firm Efficiency.
                </div>
              </div>
              <div className="lg:w-[100%] lg:h-[225px] glow-on-hover w-[160px] h-[140px] flex flex-col  justify-center px-5 shadow-xl">
                <div className="flex mb-3">
                  <img
                    src={client}
                    className="w-[40px] h-[40px] lg:w-[67px] lg:h-[67px]"
                  ></img>
                  <div className="font-bold text-[11px] leading-[16px] lg:text-[24px] lg:leading-[30px] my-auto text-left ml-2 lg:ml-5">
                    Mobile, Web And Windows Client
                  </div>
                </div>
                <div className="text-tools2 text-left text-[9px] leading-[13px] lg:text-[18px] lg:leading-[28px]">
                  This Feature Allow users interact seamlessly with the system
                  across various platforms as per there convenience.
                </div>
              </div>
            </div>
          )}

          <IoIosArrowForward
            onClick={() => {
              if (page == 0) setPage(1);
            }}
            fontSize="3rem"
            className="ml-5 my-auto hidden lg:block"
          />
        </div>
      </div>
      <div className="flex justify-center">
        {" "}
        {/* Container to center the button */}
        <button
          className="h-12 bg-gradient-to-b from-home to-linear rounded-md text-white lg:hidden"
          onClick={load}
          style={{ width: "160px" }}
        >
          VIEW {val}
        </button>
      </div>
    </div>
  );
};
export default Features;
