import React from "react";

const Tool = (props) => {
  return (
    <div
      className={`bg-white rounded-md w-[104px] h-[104px] md:w-[170px] md:h-[170px] flex flex-col justify-center items-center glow-on-hover hover:scale-105 ${!props.invisible ? 'box-shadow' : ''}`}
    >
      {!props.invisible ? (
        <img
          src={props.img}
          className="mx-auto w-[41px] h-[41px] md:w-[67px] md:h-[67px]"
        />
      ) : null}
      <p className="w-9/12 mx-auto text-center mt-3 text-[11px] md:text-[18px] md:leading-[21px] leading-[13px] ">
        {props.title}
      </p>
    </div>
  );
};

export default Tool;
