import React from "react";
import { FaCalendarDays } from "react-icons/fa6";

const News =(props)=>{
    return(
        <div className="flex my-3 ">
        <img src={props.img}></img>
        <div className="pl-2 pt-1 w-full">
            <div className="text-sm">{props.title}</div>
            <div className="flex my-1">
                <FaCalendarDays opacity='65%'/>
                <div style={{fontSize:'12px'}} className="ml-2 opacity-65">{props.date}</div>
            </div>
            
        </div>
    </div>
    )
}
export default News