import React from "react";
import { TiTick } from "react-icons/ti";
import s1 from "../assets/s1.svg";
import s2 from "../assets/s2.svg";
import s3 from "../assets/s3.svg";
import s4 from "../assets/s4.svg";
import s5 from "../assets/s5.svg";
import s6 from "../assets/s6.svg";
import s7 from "../assets/s7.svg";
import s8 from "../assets/s8.svg";
import s9 from "../assets/s9.svg";
import b1 from "../assets/b1.svg";
import b2 from "../assets/b2.svg";
import b3 from "../assets/b3.svg";
import b4 from "../assets/b4.svg";
import Numinterest from "../components/Numinterest";
import { FaRegUser } from "react-icons/fa6";
import { FaCalendarDays } from "react-icons/fa6";
import BottomLine from "../components/BottomLine";
const Services = () => {
  return (
    <div className="overflow-x-hidden">
      <div className="order-3 xl:w-full xl:ml-10 flex flex-col items-center justify-center gap-4 text-center mx-[55px] my-5 lg:my-10">
        <p className="font-bold text-[24px] leading-[28.81px] lg:text-[48px] lg:leading-[57.6px]">
          Services We Offer
        </p>
        <BottomLine />
        <p className="font-normal text-[13px] leading-[22px] lg:text-[18px] lg:leading-[32px] text-center lg:text-left">
          Take advantage of Our Comprehensive IT services for cutting-edge
          solutions tailored for your Business.
        </p>
      </div>

      <div
        className="lg:grid lg:grid-cols-3 flex items-center justify-center flex-wrap lg:gap-5 gap-2 mx-1 mb-20 lg:mx-auto lg:px-10"
        style={{ maxWidth: "1400px" }}
      >
        <div className="border-2 rounded-md w-[180px] h-[214px] lg:w-[400px] lg:h-[453px] flex flex-col items-center lg:items-start justify-center px-2 lg:px-5 glow-on-hover hover:scale-105">
          <img
            src={s1}
            className="w-[50px] h-[50px] lg:w-[107px] lg:h-[107px]"
          ></img>
          <div className="text-services mt-2 lg:mt-5 text-[7px] leading-[13px] lg:text-[16px] lg:leading-[28px]">
            Service 1
          </div>
          <div className=" my-2 text-[12px] leading-[19px] lg:text-[24px] lg:leading-[70px] font-semibold">
            Website Development
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Custom Website Design
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Full Stack Development
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              E-commerce Development
            </div>
            <TiTick color="#0594CE" />
          </div>
        </div>
        <div className="border-2 rounded-md w-[180px] h-[214px] lg:w-[400px] lg:h-[453px] flex flex-col items-center lg:items-start justify-center px-2 lg:px-5 glow-on-hover hover:scale-105">
          <img
            src={s2}
            className="w-[50px] h-[50px] lg:w-[107px] lg:h-[107px]"
          ></img>
          <div className="text-services mt-2 lg:mt-5 text-[7px] leading-[13px] lg:text-[16px] lg:leading-[28px]">
            Service 2
          </div>
          <div className="my-2 text-[12px] leading-[19px] lg:text-[24px] lg:leading-[70px] font-semibold">
            Software Development
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Agile Software Development
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Cross Platform
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Quality Assurance and Testing
            </div>
            <TiTick color="#0594CE" />
          </div>
        </div>
        <div className="border-2 rounded-md w-[180px] h-[214px] lg:w-[400px] lg:h-[453px] flex flex-col items-center lg:items-start justify-center px-2 lg:px-5 glow-on-hover hover:scale-105">
          <img
            src={s3}
            className="w-[50px] h-[50px] lg:w-[107px] lg:h-[107px]"
          ></img>
          <div className="text-services mt-2 lg:mt-5 text-[7px] leading-[13px] lg:text-[16px] lg:leading-[28px]">
            Service 3
          </div>
          <div className="my-2 text-[12px] leading-[19px] lg:text-[24px] lg:leading-[70px] font-semibold">
            Mobile Apps
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Native Mobile App Development
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Cross-Platform App Development
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              User-Centric UX/UI Design
            </div>
            <TiTick color="#0594CE" />
          </div>
        </div>
        <div className="border-2 rounded-md w-[180px] h-[214px] lg:w-[400px] lg:h-[453px] flex flex-col items-center lg:items-start justify-center px-2 lg:px-5 glow-on-hover hover:scale-105">
          <img
            src={s4}
            className="w-[50px] h-[50px] lg:w-[107px] lg:h-[107px]"
          ></img>
          <div className="text-services mt-2 lg:mt-5 text-[7px] leading-[13px] lg:text-[16px] lg:leading-[28px]">
            Service 4
          </div>
          <div className="my-2 text-[12px] leading-[19px] lg:text-[24px] lg:leading-[70px] font-semibold">
            Cloud Computing
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Cloud Application Development
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Cloud Security
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Cloud Server
            </div>
            <TiTick color="#0594CE" />
          </div>
        </div>
        <div className="border-2 rounded-md w-[180px] h-[214px] lg:w-[400px] lg:h-[453px] flex flex-col items-center lg:items-start justify-center px-2 lg:px-5 glow-on-hover hover:scale-105">
          <img
            src={s5}
            className="w-[50px] h-[50px] lg:w-[107px] lg:h-[107px]"
          ></img>
          <div className="text-services mt-2 lg:mt-5 text-[7px] leading-[13px] lg:text-[16px] lg:leading-[28px]">
            Service 5
          </div>
          <div className="my-2 text-[12px] leading-[19px] lg:text-[24px] lg:leading-[70px] font-semibold">
            UI/UX
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              User Research
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Interactive Prototyping
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Design testing
            </div>
            <TiTick color="#0594CE" />
          </div>
        </div>
        <div className="border-2 rounded-md w-[180px] h-[214px] lg:w-[400px] lg:h-[453px] flex flex-col items-center lg:items-start justify-center px-2 lg:px-5 glow-on-hover hover:scale-105">
          <img
            src={s6}
            className="w-[50px] h-[50px] lg:w-[107px] lg:h-[107px]"
          ></img>
          <div className="text-services mt-2 lg:mt-5 text-[7px] leading-[13px] lg:text-[16px] lg:leading-[28px]">
            Service 6
          </div>
          <div className="my-2 text-[12px] leading-[19px] lg:text-[24px] lg:leading-[70px] font-semibold">
            E-Commerce
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Secure Payment Gateway Integration
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Easy Online Shopping Experience
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Product Recommendations
            </div>
            <TiTick color="#0594CE" />
          </div>
        </div>
        <div className="border-2 rounded-md w-[180px] h-[214px] lg:w-[400px] lg:h-[453px] flex flex-col items-center lg:items-start justify-center px-2 lg:px-5 glow-on-hover hover:scale-105">
          <img
            src={s7}
            className="w-[50px] h-[50px] lg:w-[107px] lg:h-[107px]"
          ></img>
          <div className="text-services mt-2 lg:mt-5 text-[7px] leading-[13px] lg:text-[16px] lg:leading-[28px]">
            Service 7
          </div>
          <div className="my-2 text-[12px] leading-[19px] lg:text-[24px] lg:leading-[70px] font-semibold">
            SEO
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Strategic Keyword Optimization
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              On-Page Optimization
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Link Building and Off-Page SEO
            </div>
            <TiTick color="#0594CE" />
          </div>
        </div>
        <div className="border-2 rounded-md w-[180px] h-[214px] lg:w-[400px] lg:h-[453px] flex flex-col items-center lg:items-start justify-center px-2 lg:px-5 glow-on-hover hover:scale-105">
          <img
            src={s8}
            className="w-[50px] h-[50px] lg:w-[107px] lg:h-[107px]"
          ></img>
          <div className="text-services mt-2 lg:mt-5 text-[7px] leading-[13px] lg:text-[16px] lg:leading-[28px]">
            Service 8
          </div>
          <div className="my-2 text-[12px] leading-[19px] lg:text-[24px] lg:leading-[70px] font-semibold">
            IT Consultants
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Buisness Growth
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Cybersecurity{" "}
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Technology Roadmap
            </div>
            <TiTick color="#0594CE" />
          </div>
        </div>
        <div className="border-2 rounded-md w-[180px] h-[214px] lg:w-[400px] lg:h-[453px] flex flex-col items-center lg:items-start justify-center px-2 lg:px-5 glow-on-hover hover:scale-105">
          <img
            src={s9}
            className="w-[50px] h-[50px] lg:w-[107px] lg:h-[107px]"
          ></img>
          <div className="text-services mt-2 lg:mt-5 text-[7px] leading-[13px] lg:text-[16px] lg:leading-[28px]">
            Service 9
          </div>
          <div className="my-2 text-[12px] leading-[19px] lg:text-[24px] lg:leading-[70px] font-semibold">
            Hosting Providers
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Scalable Cloud Hosting
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Managed WordPress Hosting
            </div>
            <TiTick color="#0594CE" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="text-web text-[9px] leading-[18px] lg:text-[18px] lg:leading-[40px]">
              Dedicated Server Hosting
            </div>
            <TiTick color="#0594CE" />
          </div>
        </div>
      </div>

      <Numinterest />

      <div className="my-10 lg:my-20 lg:mx-auto" style={{ maxWidth: "1500px" }}>
        <div className="order-3 xl:w-full xl:ml-10 flex flex-col items-center justify-center gap-4 text-center mx-10 my-5 lg:my-10">
          <p className="font-bold text-[24px] leading-[28.81px] lg:text-[48px] lg:leading-[57.6px]">
            Company <span className="text-email">Blogs And Insights</span>
          </p>
          <div className="bg-gradient-to-r from-home to-linear xl:mx-0 mx-auto rounded-lg w-[65px] h-[6px]" />
          <p className="font-normal text-[13px] leading-[22px] lg:text-[18px] lg:leading-[32px] text-center lg:text-left">
            Check out our Latest blogs and insights to know more about Our
            Company Services
          </p>
        </div>

        <div className="lg:flex md:block justify-between px-3 lg:px-10">
          <div
            className="relative flex md:mb-10 lg:mb-0 sm:mb-10 w-full h-[278px] lg:h-[500px] mx-auto"
            style={{
              backgroundImage: `url(${b1})`,
              backgroundSize: "cover",
            }}
          >
            <div className="text-white absolute bottom-0 mx-10 my-10">
              <div className="flex mb-1">
                <FaRegUser />
                <div className="text-[9px] leading-4 lg:text-[16px] lg:leading-7 mx-3 my-auto">
                  reacthemes
                </div>
                <div>|</div>
                <FaCalendarDays className="mx-3 my-auto" />
                <div className="text-[9px] leading-4 lg:text-[16px] lg:leading-7 my-auto">
                  February 22, 2022
                </div>
              </div>
              <p className="text-[17px] lg:text-[24px]">
                Technology support allows erie non-profit to serve
              </p>
            </div>
          </div>

          <div className="w-full lg:pl-5 lg:mt-0 md:mt-10 flex flex-col justify-between gap-5 lg:items-center mt-5">
            <div className="flex lg:mb-0 md:mb-5 sm:mb-5 items-center">
              <img src={b2} className="w-1/3 md:w-auto lg:w-auto"></img>
              <div className="ml-5 mt-2">
                <div className="flex text-web text-sm">
                  <FaRegUser className="my-auto mr-2" />
                  <div className="text-[9px] leading-4 lg:text-[16px] lg:leading-7 my-auto">
                    reacthemes
                  </div>
                  <div className="mx-3 my-auto">|</div>
                  <FaCalendarDays className="my-auto mr-2" />
                  <div className="text-[9px] leading-4 lg:text-[16px] lg:leading-7 my-auto">
                    February 22, 2022
                  </div>
                </div>
                <div className="text-[13px] lg:text-[24px] font-bold">
                  Technology support allows erie non-profit to serve
                </div>
              </div>
            </div>
            <div className="flex lg:mb-0 md:mb-5 sm:mb-5 items-center">
              <img src={b3} className="w-1/3 md:w-auto lg:w-auto"></img>
              <div className="ml-5 mt-2">
                <div className="flex text-web text-sm">
                  <FaRegUser className="my-auto mr-2" />
                  <div className="text-[9px] leading-4 lg:text-[16px] lg:leading-7 my-auto">
                    reacthemes
                  </div>
                  <div className="mx-3 my-auto">|</div>
                  <FaCalendarDays className="my-auto mr-2" />
                  <div className="text-[9px] leading-4 lg:text-[16px] lg:leading-7 my-auto">
                    February 22, 2022
                  </div>
                </div>
                <div className="text-[13px] lg:text-[24px] font-bold">
                  Software Makes Your Profit Double if You Scale Properly
                </div>
              </div>
            </div>
            <div className="flex lg:mb-0 md:mb-5 sm:mb-5 items-center">
              <img src={b4} className="w-1/3 md:w-auto lg:w-auto"></img>
              <div className="ml-5 mt-2">
                <div className="flex text-web text-sm">
                  <FaRegUser className="my-auto mr-2" />
                  <div className="text-[9px] leading-4 lg:text-[16px] lg:leading-7 my-auto">
                    reacthemes
                  </div>
                  <div className="mx-3 my-auto">|</div>
                  <FaCalendarDays className="my-auto mr-2" />
                  <div className="text-[9px] leading-4 lg:text-[16px] lg:leading-7 my-auto">
                    February 22, 2022
                  </div>
                </div>
                <div className="text-[13px] lg:text-[24px] font-bold">
                  Designing Machine Intering For Vehicles Of The Future
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
