import React from "react";
import features from "../assets/MasterFeat.svg";
import list from "../assets/list2.svg";
const Masters = () => {
  return (
    <div className="flex text-left">
      <img src={features} className="w-1/2 hidden lg:block"></img>
      <div className="w-full lg:ml-10">
        <div className="font-bold " style={{ fontSize: "32px" }}>
          Masters
        </div>
        <div className="text-tools2 my-3">
          Explore the Power of Master Features in Our ERP Solution! Our master
          feature serves as the heart of Our System, driving Enhanced
          Functionality and Efficiency. Experience unified data management,
          streamlined processes, and insightful analytics—all designed to
          Empower Your business for Success. Discover the backbone of seamless
          operations with Our advanced ERP tool.
        </div>
        <ul className="text-tools2">
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Ledgers
          </li>
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Items
          </li>
          <li className="flex my-3">
            <img src={list} className="mr-2"></img> Bill Of Material
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Masters;
