import React from "react";
import logo from "../assets/logo-white.svg";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { IoLogoApple } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPaperPlane } from "react-icons/fa";
import News from "./News";
import news1 from "../assets/news1.svg";
import news2 from "../assets/news2.svg";
import news3 from "../assets/news3.svg";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import dice from "../assets/dice.svg";
import linkedin from "../assets/linkedin.svg";
import symbol from "../assets/symbol.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="bg-gradient-to-b from-home to-linear text-white xl:px-20 px-10">
      <div className="xl:flex justify-between">
        <div className="my-10 py-5 xl:w-1/3">
          <img src={logo}></img>
          <div className="font-bold mt-7" style={{ fontSize: "20px" }}>
            Download the App
          </div>
          <div className="flex mt-3">
            <div className="flex h-12 border px-2 mr-2 border-white border-opacity-20">
              <IoLogoGooglePlaystore className="my-auto" fontSize="1.25rem" />
              <div className="my-auto" style={{ fontSize: "17px" }}>
                Google Play
              </div>
            </div>
            <div className="flex h-12 border px-2 border-white border-opacity-20">
              <IoLogoApple className="my-auto" fontSize="1.25rem" />
              <div className="my-auto" style={{ fontSize: "17px" }}>
                App Store
              </div>
            </div>
          </div>
        </div>
        <div className="xl:flex xl:my-10 xl:py-5 xl:w-2/3">
          <div className="xl:w-1/3">
            <div className="font-bold">Useful Links</div>
            <ul className="text-base mt-5">
              <Link onClick={() => window.scrollTo(0, 0)} to="">
                <li className="my-2">Home</li>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} to="/features">
                <li className="my-2">Features</li>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} to="/pos">
                <li className="my-2">POS</li>
              </Link>
              {/* <Link onClick={() => 
    window.scrollTo(0, 0)} to="/pricing"><li className={activetab == 'Pricing' ? 'text-navbarBlue border-b-2 border-border' : 'hover:border-b-2 hover:border-black'} onClick={() => setActivetab('Pricing')}>Pricing</li></Link> */}
              <Link onClick={() => window.scrollTo(0, 0)} to="/services">
                <li className="my-2">Services</li>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} to="/about">
                <li className="my-2">About Us</li>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} to="/contact">
                <li className="my-2">Contact Us</li>
              </Link>
            </ul>
          </div>
          <div className="xl:w-1/3 w-full xl:mt-0 mt-10">
            <div className="font-bold">Latest News</div>
            <div className="mt-5 xl:pr-5">
              <News
                img={news1}
                title="Define World Best IT Solution Technology"
                date="August 29, 2019"
              />
              <News
                img={news2}
                title="You Must Try 20 Secret Of Digital Transform"
                date="July 12, 2019"
              />
              <News
                img={news3}
                title="10 PHP Frameworks You Need To Use Anywhere"
                date="June 20, 2019"
              />
            </div>
          </div>
          <div className="xl:w-1/3 pl-1 xl:mt-0 mt-10 ">
            <div className="font-bold mb-5">News Letter</div>
            <div className="py-3 text-sm opacity-65">
              Sign up today for hints, tips and the<br></br>latest product news
            </div>
            <div
              className="bg-white flex justify-between"
              style={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                width: "240px",
              }}
            >
              <div className="flex bg-gray_email w-full text-email">
                <MdEmail className="my-auto mx-3" color="#0594CE" />
                <div className="my-auto">Email Address..</div>
              </div>
              <div className="my-auto py-3 px-4">
                <FaPaperPlane className="my-auto" color="#0594CE" />
              </div>
            </div>
            <div className="mt-5">Follow Us On</div>
            <div className="flex my-3">
              <img src={facebook}></img>
              <img src={twitter} className="mx-2"></img>
              <img src={dice} className="mr-2"></img>
              <img src={linkedin}></img>
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr></hr>
        <div className=" text-center py-5 text-base">
          © Copyright <a href="https://clearconceptsolutions.com">Clear Concept Solutions</a> 2024. All Rights Reserved
        </div>
      </div>
    </div>
  );
};
export default Footer;
