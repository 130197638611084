import React from "react";
import icon from "../assets/icon.svg"
const Poscard=(props)=>{
    return(
        <div className="bg-white shadow-lg rounded-lg px-5 py-14 glow-on-hover hover:scale-105">
            <img src={icon}></img>
            <div className="my-6 font-semibold" style={{fontSize:'24px',lineHeight:'28px'}}>{props.title}</div>
            <div style={{fontSize:'16px',lineHeight:'23px'}}>{props.content}</div>
        </div>
    )
}
export default Poscard