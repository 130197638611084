import React, {useState, useRef, useEffect} from "react";
const Numbers = (props) => {
  const [counted, setCounted] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Trigger when at least 50% of the component is visible
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (!counted) {
            setCounted(true);
            startCounting();
          }
        } else {
          setCounted(false);
        }
      });
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [counted]);

  const startCounting = () => {
    let count = 0;
    const target = parseInt(props.num, 10);
    const step = Math.ceil(target / 100); // Divide the total count into steps

    const interval = setInterval(() => {
      count += step;
      if (count >= target) {
        count = target;
        clearInterval(interval);
      }
      ref.current.children[0].innerText = `${count}${props.subTitle || ''}`;
    }, 25); // Adjust the interval as needed for smooth counting
  };
  return (
    <div className=" mb-5" ref={ref}>
      <p className="font-bold text-[35px] leading-[56px]">0</p>
      <p className="text-[13px] lg:text-[14px] leading-[20px] lg:leading-[22px] font-semibold ">
        {props.description}
      </p>
    </div>
  );
};
export default Numbers;
