import React from "react";

const GoogleMap = () => {
  return (
    <div className="w-full h-[190px] lg:h-[390px] opacity-80">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.2373104782014!2d73.87617697599252!3d18.51817516924578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c04b00000001%3A0xf8bb51981e99bbdf!2sABS%20Software%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1716577403722!5m2!1sen!2sin&zoom=20"
        width="100%"
        height="100%"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        style={{ border: 0 }}
      ></iframe>
    </div>
  );
};

export default GoogleMap;
