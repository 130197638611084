import React from "react";
const Keyfeat = (props) => {
  return (
    <div className="border border-1 lg:border-white bg-gradient-to-r from-home to-linear lg:bg-white lg:bg-opacity-20 rounded-md w-full h-[250px] lg:w-[310px] lg:h-[282px] flex flex-col justify-center p-2 lg:px-5 mb-5 glow-on-hover hover:scale-105 transition-all duration-300 ease">
      <div className="bg-white w-[30px] h-[30px] lg:w-[60px] lg:h-[60px] flex items-center justify-center rounded-full">
        <img
          src={props.img}
          className="w-[15px] h-[15px] lg:w-[30px] lg:h-[30px]"
        />
      </div>
      <p className="font-bold my-3 text-[11px] leading-[16px] lg:text-[22px] lg:leading-[32px]">
        {props.title}
      </p>
      <p className="text-[10px] leading-[13px] lg:text-[16px] lg:leading-[26px]">
        {props.content}
      </p>
    </div>
  );
};
export default Keyfeat;
