import React from "react";
import about1 from "../assets/about.svg";
import about2 from "../assets/about2.svg";
import founder1 from "../assets/founder1.svg";
import founder2 from "../assets/founder2.svg";
import fund1 from "../assets/fund1.svg";
import fund2 from "../assets/fund2.svg";
import fund3 from "../assets/fund3.svg";
import fund4 from "../assets/fund4.svg";
import fund5 from "../assets/fund5.svg";
import point from "../assets/Point.svg";
import lineup from "../assets/lineup.svg";
import lineright from "../assets/lineright.svg";
import lineleft from "../assets/lineleft.svg";
import linedown from "../assets/linedown.svg";
import img1 from "../assets/new1.svg";
import img2 from "../assets/new2.svg";
import testBack from "../assets/testimonial.svg";
import custm1 from "../assets/client1.svg";
import custm2 from "../assets/client2.svg";
import list3 from "../assets/list3.svg";
import BottomLine from "../components/BottomLine";
const About = () => {
  const eventsAbout = [
    {
      title:
        "Market had DOS based software for billing. ABS started with windows based software.",
      year: 1999,
      direction: "top",
    },
    {
      title:
        "Started computerized billing workflows according to the customer requirement.",
      year: 2002,
      direction: "down",
    },
    {
      title: "VAT implementation as per indian government Laws.",
      year: 2006,
      direction: "top",
    },
    {
      title: "Started enhancing views and reports.",
      year: 2009,
      direction: "down",
    },
    {
      title: "Tool Based working with modular codebase.",
      year: 2012,
      direction: "down",
    },
    {
      title:
        "Restarted development on ABS Cloud with new approach required in the market.",
      year: 2013,
      direction: "top",
    },
    {
      title: "Implementation of GST as per Indian laws.",
      year: 2017,
      direction: "down",
    },
    {
      title: "Started Providing ERP & IT Services",
      year: 2024,
      direction: "top",
    },
    // {
    //   title: "Ullamco est sit aliqua dolor ",
    //   year: 2022,
    //   direction: "down",
    // },
    // {
    //   title: "Amet minim mollit non deserunt",
    //   year: 2023,
    //   direction: "top",
    // },
  ];
  return (
    <div>
      <div className="relative mb-20">
        <img src={about1} className="w-full" alt="About Us Image" />
        <div
          className="absolute inset-0 flex items-center mx-auto lg:mx-0 w-max lg:left-20 font-bold text-white"
          style={{ fontSize: "40px" }}
        >
          About us
        </div>
      </div>

      <div className="flex mx-5 lg:mx-20 lg:px-5 py-10 my-10">
        <img src={about2} className="hidden lg:block w-1/2 mr-20"></img>
        <div className="w-full my-auto">
          <p className="font-bold text-[20px] text-center lg:text-left leading-[28.81px] lg:text-[48px] lg:leading-[57.6px] lg:mb-2">
            SIMPLIFY, AUTOMATE, SUCCEED!
          </p>

          <img src={img1} className="mx-auto w-9/12 mt-5  lg:hidden"></img>
          <img
            src={img2}
            className="mx-auto mt-5 w-9/12  lg:hidden mb-10"
          ></img>
          <p className="text-tools2 text-[13px] leading-[19px] lg:text-[21px] lg:leading-[34px]">
            We are dedicated to revolutionizing entrepreneurship with our
            all-in-one solution, designed to streamline business processes for
            entrepreneurs. Our recent focus is on standardizing our solution,
            ensuring that it seamlessly integrates with existing workflows while
            offering a comprehensive set of features. Our goal is to provide
            entrepreneurs with a user-friendly platform that not only simplifies
            their day-to-day operations but also facilitates easy customization
            to suit their unique needs.
          </p>
        </div>
      </div>
      <hr className="hidden xl:block"></hr>
      <div className="xl:flex mx-20 my-10 justify-between hidden ">
        <div className="font-bold text-base my-auto">
          Trusted and funded by <br></br>more then 800 companies
        </div>
        <img
          src={fund1}
          className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
        ></img>
        <img
          src={fund2}
          className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
        ></img>
        <img
          src={fund3}
          className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
        ></img>
        <img
          src={fund4}
          className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
        ></img>
        <img
          src={fund5}
          className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
        ></img>
      </div>
      <div className="xl:hidden">
        <div className="font-bold text-base my-auto text-center">
          Trusted and funded by more then 800 companies
        </div>
        <div className="flex justify-between  w-full mx-auto mt-10">
          <img
            src={fund1}
            className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
          ></img>
          <img
            src={fund2}
            className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
          ></img>
          <img
            src={fund3}
            className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
          ></img>
        </div>
        <div className="flex w-8/12 justify-between mx-auto mt-10">
          <img
            src={fund4}
            className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
          ></img>
          <img
            src={fund5}
            className="w-[110px] h-[52px] lg:w-[167px] lg:h-[80px]"
          ></img>
        </div>
      </div>
      <div className="font-semibold text-[20px] text-center leading-[28.81px] lg:text-[48px] lg:leading-[57.6px] mt-20">
        Hear From Our Founders
      </div>
      <div className="flex items-center justify-center">
        <BottomLine />
      </div>
      <div className="lg:flex block lg:mx-20  px-5 my-10 lg:my-20">
        <img
          src={founder1}
          className="lg:w-5/12 mx-auto w-10/12 lg:mx-0 lg:mr-10"
        ></img>
        <div className="w-full lg:ml-10 mt-10 lg:text-left text-center">
          <div className="font-semibold text-[22px] leading-[31px] lg:text-[45px] lg:leading-[55px]">
            Abbas Bhagat
          </div>
          <div className="text-tools my-3 text-[16px] leading-[18px] lg:text-[22px] lg:leading-[31px]">
            Founder and Managing director
          </div>
          <div className="text-about mt-2 mb-5 text-[14px] leading-[19px] lg:text-[21px] lg:leading-[34px]">
            I ignited a passion for programming in students of graduation and
            post-graduation by teaching C, C++, and Java, guiding them in
            developing real-world projects for academic success. Furthermore, my
            experience helped me to build custom programming solutions for
            businesses, streamlining their operations and enhancing automation
            capabilities.<br></br>I possess a deep interest in the intricacies
            of business dynamics. I maintain an insatiable curiosity, constantly
            exploring the ever-evolving potential of technology and its ability
            to shape businesses for the better.
          </div>
        </div>
      </div>
      {/* <div className="lg:flex block lg:mx-20 px-5 my-10 py-10 lg:my-20 bg-home bg-opacity-10">
        <img
          src={founder2}
          className="lg:w-5/12 mx-auto w-10/12 lg:mx-0 lg:mr-10 block lg:hidden"
        ></img>
        <div className="w-full lg:ml-10 mt-10 lg:text-left text-center">
          <div className="font-semibold text-[22px] leading-[31px] lg:text-[45px] lg:leading-[55px]">
            Zainulabedin Wagh
          </div>
          <div className="text-tools my-3 text-[16px] leading-[18px] lg:text-[22px] lg:leading-[31px]">
            Business development and Investor
          </div>
          <div className="text-about mt-2 mb-5 text-[14px] leading-[19px] lg:text-[21px] lg:leading-[34px]">
            Driven by a deep fascination for computers and coding, he has spent
            over five years leveraging technology to empower businesses. With a
            keen understanding of the impact technology has on growth and
            innovation, he has collaborated with startups, contributing to the
            development and implementation of technology solutions.<br></br>
            Expanding their skill set, he acquired expertise in UX design,
            serving as a strategist for multiple startups. This combined
            understanding of technology and user experience enables him to craft
            products that deliver exceptional user journeys.
          </div>
        </div>
        <img src={founder2} className="w-5/12 hidden lg:block"></img>
      </div> */}
      <div className="my-20 lg:mb-40">
        <div className="font-semibold text-[20px] text-center leading-[28.81px] lg:text-[48px] lg:leading-[57.6px] mt-20 mb-30">
          Our 20+ Years Record
        </div>
        <div className="flex items-center justify-center">
          <BottomLine />
        </div>
      </div>

      <div className="relative mb-20 hidden lg:block">
        <hr className="h-1 bg-hr  w-full" style={{ top: "10px" }}></hr>
        <div className="w-full flex justify-between px-10">
          {eventsAbout.map((event, index) => (
            <div key={index}>
              {event.direction === "down" ? (
                <div style={{ transform: "translateY(-44px)" }}>
                  <div
                    className="w-min mx-auto text-hr font-bold"
                    style={{ fontSize: "20px" }}
                  >
                    {event.year}
                  </div>
                  <img src={point} className="mx-auto"></img>
                  <img src={linedown} className="mx-auto"></img>
                  <div className="text-center text-sm text-year lg:w-[150px]">
                    {event.title}
                  </div>
                </div>
              ) : (
                <div style={{ transform: "translateY(-105px)" }}>
                  <div className="text-center text-sm text-year lg:w-[150px]">
                    {event.title}
                  </div>
                  <img src={lineup} className="mx-auto"></img>
                  <img src={point} className="mx-auto"></img>
                  <div
                    className="w-min mx-auto text-hr font-bold"
                    style={{ fontSize: "20px" }}
                  >
                    {event.year}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="mx-auto w-max relative lg:hidden mb-10">
          <div
            className=" w-1 bg-hr mx-auto absolute"
            style={{ transform: "translate(133px,-50px)", height: "680px" }}
          ></div>
          {eventsAbout.map((event, index) => (
            <div key={index}>
              {event.direction === "down" ? (
                <div>
                  <div
                    className="flex mb-10"
                    style={{ transform: "translate(58px, -8px)" }}
                  >
                    <div
                      className="my-auto mr-5 text-hr font-bold"
                      style={{ fontSize: "20px" }}
                    >
                      {event.year}
                    </div>
                    <img src={point}></img>
                    <img src={lineright}></img>
                    <div
                      className="text-year text-sm"
                      style={{ width: "150px" }}
                    >
                      {event.title}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className="flex"
                    style={{ transform: "translate(-56px, -25px)" }}
                  >
                    <div
                      style={{ width: "150px" }}
                      className="text-year text-sm"
                    >
                      {event.title}
                    </div>

                    <img src={lineleft}></img>
                    <img src={point}></img>

                    <div
                      className="my-auto ml-5 text-hr font-bold"
                      style={{ fontSize: "20px" }}
                    >
                      {event.year}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div
        className="lg:flex items-center lg:px-20 py-20"
        style={{
          backgroundImage: `url(${testBack})`,
          backgroundColor: "#F2F5F9",
        }}
      >
        <div className=" lg:mr-0 lg:ml-10 lg:w-1/2 lg:py-10">
          <div className="font-semibold ml-3 text-center lg:text-left text-[24px] leading-[34px] lg:text-[45px] lg:leading-[55px]">
            Hear from our happy customers who have used our service
          </div>
          <div className="ml-3 mt-5 text-center lg:text-left text-[13px] leading-[22px] lg:text-[18px] lg:leading-[30px] px-2">
            Experience the difference with our service through the stories of
            our satisfied customers.
          </div>
          <div className="flex mt-5">
            <div className="flex w-1/2">
              <img
                src={list3}
                className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px]"
              ></img>
              <div className="lg:mt-3 text-[11px] leading-[18px] lg:text-[16px] lg:leading-[26px]">
                We hire professionals you can trust
              </div>
            </div>
            <div className="flex w-1/2">
              <img
                src={list3}
                className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px]"
              ></img>
              <div className="lg:mt-3 text-[11px] leading-[18px] lg:text-[16px] lg:leading-[26px]">
                We promise to respect your time
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/2">
              <img
                src={list3}
                className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px]"
              ></img>
              <div className="lg:mt-3 text-[11px] leading-[18px] lg:text-[16px] lg:leading-[26px]">
                We promise to provide upfront pricing
              </div>
            </div>
            <div className="flex w-1/2">
              <img
                src={list3}
                className="w-[30px] h-[30px] lg:w-[60px] lg:h-[60px]"
              ></img>
              <div className="lg:mt-3 text-[11px] leading-[18px] lg:text-[16px] lg:leading-[26px]">
                We promise to provide Best Services{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 lg:mr-10 ">
          <img src={custm1} className="mx-auto lg:mx-0 w-full"></img>
          <img
            src={custm2}
            className="mx-auto lg:mx-0 w-full"
            style={{ marginTop: "-10%" }}
          ></img>
        </div>
      </div>
    </div>
  );
};
export default About;
