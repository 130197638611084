import React, { useState } from "react";
import { TiTick } from "react-icons/ti";
import img1 from "../assets/PosPower.svg";
import img2 from "../assets/PosDesign.svg";
import img3 from "../assets/PosBusiness.svg";
import Numinterest from "../components/Numinterest";
import Poscomp from "../components/Poscomp";
import Poscard from "../components/Poscard";
import BottomLine from "../components/BottomLine";

const Pos = () => {
  const [page, setPage] = useState(0);
  const [display, setDisplay] = useState(false);
  const [val, setVal] = useState("MORE");
  const load = () => {
    setDisplay(!display);
    if (val == "MORE") setVal("LESS");
    else setVal("MORE");
  };
  return (
    <div className="my-10">
      <div className="font-bold text-[24px] leading-[28.81px] lg:text-[48px] lg:leading-[57.6px] text-center">
        POS Features
      </div>
      <div className="flex items-center justify-center">
        <BottomLine />
      </div>
      <div className="text-center mx-auto lg:w-7/12 text-[13px] leading-[22px] lg:text-[20px] lg:leading-[32px]">
        {" "}
        A comprehensive suite tailored to enhance Operational Efficiency,
        Improve Accuracy, and Elevate your ERP management capabilities.
      </div>
      <div className="block lg:flex mx-10 my-20 justify-between">
        <img src={img1} className="w-9/12 lg:hidden mx-auto mb-5"></img>
        <div className="w-full mr-10">
          <div className="text-[24px] leading-[28.81px] lg:text-[48px] lg:leading-[57.6px]">
            Unleash the Power of POS
          </div>
          <div
            className="text-[13px] leading-[22px] lg:text-[20px] lg:leading-[32px] my-3 font-light"
            style={{ color: "#5E6075" }}
          >
            Our innovative POS systems will help you streamline your operations
            and improve the client experience. POS translates to happier
            customers, reduced wait times, and valuable data insights.
          </div>
          <div style={{ fontSize: "20px" }}>
            <div className="flex my-4">
              <TiTick
                className="my-auto mr-1"
                color="#FF825C"
                fontSize="40px"
              />
              <p className="text-[13px] leading-[22px] lg:text-[20px] lg:leading-[32px]">
                Self-Checkout : Pay for your purchases quickly and easily,
                avoiding queues and getting you back to your day sooner.
              </p>
            </div>
            <div className="flex mb-4">
              <TiTick
                className="my-auto mr-1"
                color="#FF825C"
                fontSize="35px"
              />
              <p className="text-[13px] leading-[22px] lg:text-[20px] lg:leading-[32px]">
                PIM : It acts as a central hub, meticulously organizing and
                managing all your product data
              </p>
            </div>
          </div>
          <button className="text-base bg-email text-white mt-3 px-4 py-3 rounded-3xl border-2 border-transparent glow-on-hover hover:text-navbarBlue hover:bg-white hover:border-border">
            Get Started With ERP Solutions
          </button>
        </div>
        <img src={img1} className="hidden lg:block w-1/2"></img>
      </div>
      <div className="bg-home bg-opacity-10 py-20">
        <div className="font-bold text-[24px] leading-[28.81px] lg:text-[48px] lg:leading-[57.6px] text-center mb-10">
          Why ABS POS System
        </div>
        <div
          className="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:mx-auto mx-10"
          style={{ maxWidth: "1400px" }}
        >
          <Poscard
            title="Versatile POS System"
            content="With our cutting-edge POS solutions, enjoy smooth transactions and enhanced operations."
          />
          <Poscard
            title="Integration"
            content="Streamline your processes with comprehensive integrations that seamlessly connect every aspect of your business."
          />
          <Poscard
            title="Secure Payment"
            content="Protect your transactions and customer data with our robust and secure payment solutions tailored for retail environments."
          />
          <Poscard
            title="what's selling"
            content="Acquire up-to-date knowledge on market trends and sales performance to make well-informed decisions about product placement."
          />
        </div>
      </div>
      <Poscomp />
      <Numinterest />
      <div className="block lg:flex mx-10 my-20 justify-between">
        <img src={img3} className="w-9/12 lg:hidden mx-auto mb-5"></img>
        <div className="w-full mr-10">
          <div
            className="font-semibold text-[24px] leading-[28.81px] lg:text-[48px] lg:leading-[57.6px]"
            style={{ fontWeight: "550" }}
          >
            Revolutionize Your Business
          </div>
          <div
            className="text-[13px] lg:text-[20px] lg:leading-[32px] my-3 font-light"
            style={{ lineHeight: "38px", color: "#5E6075" }}
          >
            Ditch the outdated register and watch your business thrive with our
            innovative POS software. Get started with our POS system as Our
            user-friendly interface makes it easy for anyone to get up and
            running quickly, so you can focus on what matters most – growing
            your success.
          </div>
          <div style={{ fontSize: "20px" }}></div>
          <button className="text-base bg-email text-white mt-3 px-4 py-3 rounded-3xl  border-2 border-transparent glow-on-hover hover:text-navbarBlue hover:bg-white hover:border-border">
            Get Started With ERP Solutions
          </button>
        </div>
        <img className="hidden lg:block w-1/2" src={img3}></img>
      </div>
    </div>
  );
};
export default Pos;
