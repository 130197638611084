import React from "react";
import bg3 from "../assets/bg3_new.svg";
import new4 from "../assets/PosDesign.svg";
const Poscomp = () => {
  return (
    <div className="relative pb-10 mb-10">
      <img src={bg3} className="absolute top-10 w-full lg:hidden" />
      <div className="pt-20 lg:flex lg:mx-20">
        <img
          src={new4}
          className="relative lg:w-1/2 z-10 mx-auto w-10/12"
        ></img>
        <div className="lg:w-1/2 lg:pl-10 px-5">
          <div className="font-bold text-center mt-10 lg:text-left lg:text-5xl text-3xl ">
            Powerful Features with <br></br>
            <span className=" text-home">Incredible Design</span>
          </div>
          <div className="mt-10">
            <div className="grid grid-cols-2 gap-5 pl-4 md:px-0">
              <div className="p-2 flex flex-col gap-2 md:gap-4 hover:scale-105 glow-on-hover">
                <p className="bg-home text-white w-[28px] h-[28px] md:w-[48px] md:h-[48px] rounded-full text-[13px] leading-[28px] md:text-[26px] md:leading-[53px] flex items-center justify-center">
                  01
                </p>
                <p className="font-bold text-[13px] leading-[14px] md:text-[24px] md:leading-[28px]">
                  Retail POS
                </p>
                <p className=" text-tools2 w-11/12 text-[12px] leading-[15px] md:text-[17px] md:leading-[23px]">
                  Empower your retail business with an efficient and intuitive
                  POS system tailored to your unique needs.
                </p>
              </div>
              <div className="p-2 flex flex-col gap-2 md:gap-4 hover:scale-105 glow-on-hover">
                <p className="bg-home text-white w-[28px] h-[28px] md:w-[48px] md:h-[48px] rounded-full text-[13px] leading-[28px] md:text-[26px] md:leading-[53px] flex items-center justify-center">
                  02
                </p>
                <p className="font-bold text-[13px] leading-[14px] md:text-[24px] md:leading-[28px]">
                  Quick Checkout
                </p>
                <p className=" text-tools2 w-11/12 text-[12px] leading-[15px] md:text-[17px] md:leading-[23px]">
                  Our Quick Checkout feature ensures seamless transactions for
                  your customers.
                </p>
              </div>
              <div className="p-2 flex flex-col gap-2 md:gap-4 hover:scale-105 glow-on-hover">
                <p className="bg-home text-white w-[28px] h-[28px] md:w-[48px] md:h-[48px] rounded-full text-[13px] leading-[28px] md:text-[26px] md:leading-[53px] flex items-center justify-center">
                  03
                </p>
                <p className="font-bold text-[13px] leading-[14px] md:text-[24px] md:leading-[28px]">
                  Integrated Inventory System
                </p>
                <p className=" text-tools2 w-11/12 text-[12px] leading-[15px] md:text-[17px] md:leading-[23px]">
                  Efficiently manage your inventory with our seamlessly
                  integrated inventory system.
                </p>
              </div>
              <div className="p-2 flex flex-col gap-2 md:gap-4 hover:scale-105 glow-on-hover">
                <p className="bg-home text-white w-[28px] h-[28px] md:w-[48px] md:h-[48px] rounded-full text-[13px] leading-[28px] md:text-[26px] md:leading-[53px] flex items-center justify-center">
                  04
                </p>
                <p className="font-bold text-[13px] leading-[14px] md:text-[24px] md:leading-[28px]">
                  Timeclock
                </p>
                <p className=" text-tools2 w-11/12 text-[12px] leading-[15px] md:text-[17px] md:leading-[23px]">
                  Track employee hours accurately with our timeclock feature,
                  simplifying payroll processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Poscomp;
